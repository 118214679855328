






























































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  computed,
  onBeforeUnmount,
} from "@vue/composition-api";
import {
  AtomDate,
  AtomText,
  AtomTextTypeEnum,
  AtomMoney,
  MolPagination,
  OrgTable,
  IOrgTableProps,
  OrgHeaderInfo,
} from "@/v2/new-design-system";
import { PayoutDownload } from "../download";
import { PayoutBannerBankAccount } from "../banner-bank-account";
import {
  fetchPayouts,
  IFetchPayoutsResponse,
} from "@/v2/repo/payout/fetch-payouts";
import { watchFlag, unWatchFlag } from "@/v2/core/feature-flag";
import { VIEW_PAYOUT_PAYEE } from "@/utils/enums/permissions";
import { useStore } from "@/store";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";

const css = bemBuilder("payout-main");

export default defineComponent({
  name: "PayoutMain",
  components: {
    AtomText,
    AtomDate,
    AtomMoney,
    MolPagination,
    OrgTable,
    OrgHeaderInfo,
    PayoutDownload,
    PayoutBannerBankAccount,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formatDate = "DD MMM YYYY";
    const isLoading = ref(false);
    const orderBy = ref();
    const sort = ref();

    const payoutList = ref<IFetchPayoutsResponse["data"]>([]);
    const paginatorInfo = ref<IFetchPayoutsResponse["paginator"]>();

    const totalPayouts = computed(() => {
      return paginatorInfo.value?.total ?? 0;
    });

    const columnsConfig: IOrgTableProps["columns"] = {
      period: {
        header: () => t("module.payout.period"),
        sortable: true,
        sortDirection: "DESC",
        show: true,
        width: 230,
      },
      description: {
        header: () => t("module.payout.description"),
        sortable: false,
        sortDirection: "NONE",
        show: true,
        width: 220,
      },
      createdAt: {
        header: () => t("module.payout.created_on"),
        sortable: false,
        sortDirection: "NONE",
        show: true,
        width: 120,
      },
      amount: {
        header: () => t("module.payout.payable_amount"),
        sortable: false,
        sortDirection: "NONE",
        show: true,
        width: 150,
      },
    };

    async function sortColumn(key: string, sortDirection: "ASC" | "DESC") {
      if (
        (!key && !sortDirection) ||
        (key === orderBy.value && sortDirection === sort.value)
      ) {
        return;
      }

      orderBy.value = key;
      sort.value = sortDirection;
      await getPayouts();
    }

    async function getPayouts(currentPage: number = 1) {
      isLoading.value = true;

      try {
        const { data, paginator } = await fetchPayouts({
          businessId: props.businessId,
          page: currentPage,
          itemsPerPage: 15,
          orderBy: orderBy.value,
          sort: sort.value,
        });

        payoutList.value = data;
        paginatorInfo.value = paginator;
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isLoading.value = false;
      }
    }

    const showBannerBankAccount = ref(false);
    const payoutsFlagName = "payouts-business-bank-account";

    const { getters } = useStore();

    const updateFlagHandler = (newValue: boolean) => {
      const permissions = getters["auth/getPermissions"];
      showBannerBankAccount.value =
        newValue && permissions.includes(VIEW_PAYOUT_PAYEE);
    };

    watchFlag(payoutsFlagName, updateFlagHandler);

    onBeforeUnmount(() => {
      unWatchFlag(payoutsFlagName, updateFlagHandler);
    });

    return {
      t,
      css,
      columnsConfig,
      payoutList,
      formatDate,
      getPayouts,
      totalPayouts,
      sortColumn,
      isLoading,
      paginatorInfo,
      showBannerBankAccount,
      AtomTextTypeEnum,
    };
  },
});
