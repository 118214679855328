






















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomAnchor,
  AtomAnchorSizeEnum,
} from "@/v2/new-design-system";
import { fetchBusinessPayoutPayee } from "@/v2/repo/business-settings";
import { IBusinessSettingsRouteEnum } from "@/v2/module/business-settings/business-settings-route.enum";
import { t } from "@/i18n";

const css = bemBuilder("payout-bank-account");

export default defineComponent({
  name: "PayoutBankAccount",
  components: {
    AtomText,
    AtomAnchor,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const showBanner = ref(false);

    onMounted(() => {
      getBusinessPayout();
    });

    async function getBusinessPayout() {
      showBanner.value = false;
      const response = await fetchBusinessPayoutPayee(props.businessId);

      if (!response?.bankAccount) {
        showBanner.value = true;
      }
    }

    return {
      t,
      css,
      showBanner,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomAnchorSizeEnum,
      IBusinessSettingsRouteEnum,
    };
  },
});
