import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";
import { FileTypeEnum } from "@/v2/util/download-file";

interface IDownloadPayoutBody {
  businessId: string;
  payoutId: string;
}

interface IDownloadPayoutResponse {
  data: string;
  fileName: string | undefined;
  fileType: FileTypeEnum | undefined;
}

export class DownloadPayoutError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: DownloadPayout");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function downloadPayout(
  request: IDownloadPayoutBody
): Promise<IDownloadPayoutResponse> {
  let response: IDownloadPayoutResponse;

  try {
    response = await http
      .get(
        `/api/v3/businesses/${request.businessId}/statements-of-accounts/${request.payoutId}/download`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const fileName = extractFileNameFromResponse(response);
        const fileType = getFileTypeFromResponse(response);

        return {
          data: response.data,
          fileName: fileName,
          fileType: fileType,
        };
      });
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new DownloadPayoutError(error);
  }

  return response;
}

function extractFileNameFromResponse(
  response: AxiosResponse
): string | undefined {
  const contentDisposition = response.headers["content-disposition"];
  let fileName;

  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="?([^\n"]*)"?/);
    if (fileNameMatch && fileNameMatch.length === 2) {
      fileName = fileNameMatch[1];
    }
  }

  return fileName;
}

function getFileTypeFromResponse(
  response: AxiosResponse
): FileTypeEnum | undefined {
  const contentType = response.headers["content-type"] ?? "";

  const enumKeys = Object.keys(FileTypeEnum) as Array<
    keyof typeof FileTypeEnum
  >;

  const enumKey = enumKeys.find((key) => {
    return contentType.includes(FileTypeEnum[key]);
  });

  return enumKey ? FileTypeEnum[enumKey] : undefined;
}
