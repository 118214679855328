import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";
import { CurrencyCodeEnum } from "@/v2/enum";

export type TypeSort = "ASC" | "DESC";
export type TypeOrderByIn = "description" | "period" | "amount" | "createdAt";
type TypeOrderByOut = "description" | "start_date" | "amount" | "created_at";

interface IFetchPayoutsRequest {
  businessId: string;
  page: number;
  itemsPerPage: number;
  orderBy: TypeOrderByIn;
  sort: TypeSort;
}

interface IPayoutsParams {
  page: number;
  per_page: number;
  order_by: TypeOrderByOut;
  sort: TypeSort;
}

export interface IFetchPayoutsResponse {
  data: Array<{
    id: string;
    description: string;
    startDate: Date;
    endDate: Date;
    amount: number;
    currency: CurrencyCodeEnum;
    fileName: string;
    createdAt: Date;
  }>;
  paginator: {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
  };
}

export class FetchPayoutsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchPayouts");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchPayouts(
  request: IFetchPayoutsRequest
): Promise<IFetchPayoutsResponse> {
  let response: AxiosResponse;

  const mapKeys: Record<TypeOrderByIn, TypeOrderByOut> = {
    description: "description",
    period: "start_date",
    amount: "amount",
    createdAt: "created_at",
  };

  const params: IPayoutsParams = {
    page: request.page,
    per_page: request.itemsPerPage,
    order_by: mapKeys[request.orderBy],
    sort: request.sort,
  };

  try {
    response = await http.get(
      `/api/v3/businesses/${request.businessId}/statements-of-accounts`,
      {
        params,
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchPayoutsError(error);
  }

  return {
    data: response.data.data.map((obj: IDataResponse) => ({
      id: obj.id,
      description: obj.description,
      startDate: new Date(obj.start_date),
      endDate: new Date(obj.end_date),
      amount: obj.amount,
      currency: obj.currency,
      fileName: obj.file_name,
      createdAt: new Date(obj.created_at),
    })),
    paginator: {
      total: response.data.meta.total,
      perPage: response.data.meta.per_page,
      currentPage: response.data.meta.current_page,
      lastPage: response.data.meta.last_page,
    },
  };
}

interface IDataResponse {
  id: string;
  description: string;
  start_date: string;
  end_date: string;
  amount: number;
  currency: CurrencyCodeEnum;
  file_name: string;
  created_at: string;
}
