






















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref } from "@vue/composition-api";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomIcon,
} from "@/v2/new-design-system";
import { downloadPayout } from "@/v2/repo/payout/download-payout";
import { downloadFile, FileTypeEnum } from "@/v2/util/download-file";
import { VTooltip } from "v-tooltip";
import { t } from "@/i18n";

const css = bemBuilder("payout-download");

export default defineComponent({
  name: "PayoutDownload",
  components: {
    AtomButton,
    AtomIcon,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);

    async function accessPayout() {
      isLoading.value = true;

      const file = await downloadPayout({
        businessId: props.businessId,
        payoutId: props.id,
      });

      downloadFile(
        file.data,
        file.fileType ?? FileTypeEnum.XLS,
        file.fileName ?? props.fileName
      );

      isLoading.value = false;
    }

    return {
      t,
      css,
      isLoading,
      accessPayout,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
